import * as React from "react";
import styled from "styled-components";
import { breakpoints } from "../styles/variables";
import { PageProps, graphql } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import RiotBar from "@riotgames/riotbar-react";
import Seo, { SeoData } from "../components/Seo/Seo";
import Purchase, { GameEdition, GameLogo } from "../components/PurchasePage/Purchase";
import { useSiteMetadata } from "../hooks/use-site-metadata";

import { StyleSheetManager } from "styled-components";
import griddie from "styled-griddie";

interface PageData {
  purchase: {
    purchaseSeo: SeoData;
    purchaseLinksHeading: string;
    heading: string;
    launchHeading: string;
    description: string;
    subheading: string;
    gameEditions: GameEdition[];
    gameLogo: GameLogo;
  };
}

const Main = styled.main`
  @media (min-width: ${breakpoints.tablet}) {
    > section {
      padding-top: 30px;
    }
  }
`;

interface Props extends PageProps<PageData> {}

const PurchasePage: React.FC<Props> = ({ data }) => {
  const { siteDomain } = useSiteMetadata();
  const { language } = useI18next();
  const { purchaseSeo } = data.purchase;

  const purchaseUrl = `https://${siteDomain}/${language}/purchase/`;

  const riotbarEnv = process.env.RIOTBAR_ENVIRONMENT === "staging" ? "staging" : "prod";

  return (
    <StyleSheetManager stylisPlugins={[griddie]}>
      <Main lang={language}>
        <Seo data={purchaseSeo} url={purchaseUrl} locale={language} />
        <RiotBar product="forge-convrgence" locale={language} environment={riotbarEnv} />
        <Purchase data={data.purchase} withBackground={false} withBgFade={false} />
      </Main>
    </StyleSheetManager>
  );
};

export default PurchasePage;

export const query = graphql`
  query ($language: String!) {
    purchase: contentstackConvrgencePurchasePage(locale: { eq: $language }) {
      ...PurchaseSeoFragment
      ...PurchaseLandingFragment
    }
    ...TranslationQueryFragment
  }
`;
